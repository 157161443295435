<template>
  <div id="g-media">
    <div class="contact">
      <img class="floating upper tablet" src="../assets/image/service/mediaAds/GoogleAds/media/contactTablet.png">
      <div class="text">
        <h2>透過接觸目標客群</h2>
        <p>Google Ads 多媒體廣告的放送範圍含括 200 多萬個網站和超過 65 萬個應用程式；無論目標對象在哪，您都能向他們放送廣告。</p>
      </div>
      <div class="lowerPart">
        <img class="floating lower phone" src="../assets/image/service/mediaAds/GoogleAds/media/contactPhone.png">
        <img class="floating lower PC" src="../assets/image/service/mediaAds/GoogleAds/media/contactPC.png">
      </div>
    </div>
    <h2>多元廣告類型</h2>
    <div class="multiType">
      <div class="type">
        <h3>文字廣告</h3>
        <p>多媒體廣告包含一行標題、兩行說明文字和一行網址，和大家常在 Google 搜尋上看到的廣告相同</p>
      </div>
      <div class="type">
        <h3>Gmail 廣告</h3>
        <p>這類自訂廣告會在 Gmail 中顯示，使用者可儲存、轉寄以及在收件匣中展開廣告。</p>
      </div>
      <div class="type">
        <h3>橫幅廣告</h3>
        <p>您可在這類圖像或互動式多媒體廣告中，添加自訂版面配置、互動元素和動畫等內容。</p>
      </div>
      <div class="type">
        <h3>應用程式廣告</h3>
        <p>您可以製作指定特定行動應用程式類別的廣告活動，還能追蹤正在放送的應用程式內廣告成效。</p>
      </div>
    </div>
    <div class="thatSimple">
      <div class="vids">
        <div class="media m1" @click.stop="playVid(1)">
          <div class="mask paused"><i class="far fa-play-circle"></i></div>
          <video src="../assets/image/service/mediaAds/GoogleAds/media/media1.mp4" muted></video>
        </div>
        <div class="media m2" @click.stop="playVid(2)">
          <div class="mask paused"><i class="far fa-play-circle"></i></div>
          <video src="../assets/image/service/mediaAds/GoogleAds/media/media2.mp4" muted></video>
        </div>
      </div>
      <div class="slogan">
        <h2>放送多媒體廣告就是這麼簡單！</h2>
        <div class="steps">
          <div class="step">
            <div class="num">1</div>
            <div class="text">
              <h3>選擇格式並製作廣告</h3>
              <p>無論是製作簡單的文字廣告，還是利用帳戶中的廣告圖庫打造圖像廣告，通通都只要幾分鐘就能完成。</p>
            </div>
          </div>
          <div class="step">
            <div class="num">2</div>
            <div class="text">
              <h3>決定廣告刊登位置</h3>
              <p>根據您對目標客群的瞭解 (例如興趣喜好或客層)，打造精準的多媒體廣告活動。</p>
            </div>
          </div>
          <div class="step">
            <div class="num">3</div>
            <div class="text">
              <h3>設定預算</h3>
              <p>決定您願意花多少錢來網羅目標對象，然後鎖定最可能對廣告訊息感興趣的客群進行宣傳。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactIdeal">
      <div class="block">
        <div class="text">
          <h3>接觸理想客戶</h3>
          <p>Google 多媒體廣告聯播網內包含數百萬個網站、新聞網頁和網誌，以及 Gmail 與 YouTube 等多個 Google 旗下網站，可接觸全球 90% 的網路使用者。只要善用指定目標、關鍵字、客層和再行銷等廣告相關設定，您就能把自家品牌推到客戶眼前，提高他們購買商品/服務的意願，進而讓過路客下手消費。</p>
        </div>
        <div class="chart chart1">
          <img src="../assets/image/service/mediaAds/GoogleAds/media/contactIdeal.png">
        </div>
      </div>
      <div class="block">
        <div class="text">
          <h3>持續推升成效</h3>
          <p>您可以根據我們團隊提供的資料評估廣告成效，從中找出反應最好的客群。無論是想衝高業績或吸引更多客戶來電，我們團隊都能協助您評估廣告活動成效並進行調整，進而達成目標</p>
        </div>
        <div class="chart chart2">
          <img src="../assets/image/service/mediaAds/GoogleAds/media/keepIncrease.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false
    };
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (
        st > $(".contact").offset().top - hook &&
        st < $(".contact").offset().top + $(".contact").height()
      ) {
        var totalHeight = hook + $(".contact").height();
        var displacement =
          $(".contact").offset().top + $(".contact").height() - st;
        $(".lower").css({
          transform:
            "translateY(" + 30 * (displacement / totalHeight) + 2 + "%)"
        });
        $(".upper").css({
          transform:
            "translateY(-" +
            (30 - (30 * (displacement / totalHeight) + 2)) +
            "%)"
        });
      }
      if (
        st > $(".thatSimple").offset().top - hook * 0.7 &&
        $(".step").length != 0
      ) {
        $(".step").each(function(idx) {
          setTimeout(function() {
            $(".step")[idx].classList.add("fadeIn");
          }, 400 * idx);
        });
      }
      if (st > $(".chart1").offset().top - hook * 0.7) {
        $(".chart1").addClass("fadeIn");
      }
      if (st > $(".chart2").offset().top - hook * 0.7) {
        $(".chart2").addClass("fadeIn");
      }
    },
    playVid(id) {
      if ($(".m" + id + " video")[0].paused) {
        $(".m" + id + " .mask").removeClass("paused");
        $(".m" + id + " video")[0].play();
      } else {
        $(".m" + id + " .mask").addClass("paused");
        $(".m" + id + " video")[0].pause();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
#g-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
    align-self: flex-start;
    font-weight: 300;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .contact {
    position: relative;
    background-image: url("../assets/image/service/mediaAds/GoogleAds/media/contactBG.jpg");
    background-position: 50% 0%;
    background-size: cover;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    overflow: hidden;
    .text {
      text-align: left;
      width: 30%;
      color: #ffffff;
      margin: 8% 0;
      transform: translateX(-40%);
    }
    .floating {
      position: absolute;
      &.tablet {
        width: 40%;
        top: 0;
        left: 48%;
      }
      &.phone {
        width: 10%;
        bottom: 0%;
        right: 45%;
        transform: translateY(32%);
      }
      &.PC {
        width: 34%;
        bottom: 0%;
        right: 5%;
        transform: translateY(32%);
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      height: auto;
      .text {
        position: relative;
        width: 80%;
        transform: translateY(0);
      }
      .floating {
        position: relative;
        margin: 0 2%;
        &.tablet {
          width: 80%;
          top: 0;
          left: 0%;
        }
        &.phone {
          width: 20%;
          left: 0%;
        }
        &.PC {
          width: 60%;
          left: 0%;
        }
      }
    }
  }
  .multiType {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .type {
      width: 40%;
      margin-bottom: 4%;
    }
    @media #{$tablet} {
      .type {
        width: 90%;
      }
    }
  }
  .thatSimple {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    margin-top: 6%;
    width: 90vw;
    .vids {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46%;
      .media {
        position: relative;
        cursor: pointer;
        margin: 0 4%;
        i {
          display: none;
          color: #474747;
        }
        & .paused {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(232, 232, 232, 0.5);
          border-radius: 30px;
          i {
            $iconSize: 80px;
            display: block;
            position: absolute;
            z-index: 1;
            font-size: $iconSize;
            top: calc(50% - #{$iconSize} / 2);
            left: calc(50% - #{$iconSize} / 2);
          }
        }
      }
    }
    .slogan {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .steps {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 4%;
        .step {
          transform: translateY(8%);
          opacity: 0;
          width: 100%;
          margin: 4% 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          h2 {
            align-self: flex-start;
          }
          h3 {
            @include font(medium);
          }
          p {
            @include font(smaller);
          }
          .num {
            font-size: 28px;
            color: #ffffff;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            background-color: $mainRed;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .text {
            width: 68%;
            margin-left: 5%;
          }
        }
      }
    }
    @media #{$laptop} {
      flex-direction: column;
      .vids {
        width: 80vw;
        justify-content: space-evenly;
        .media {
          width: 20%;
          @media #{$tablet} {
            width: 80%;
            margin: 4%;
          }
        }
        video {
          width: 100%;
        }
        @media #{$phone} {
          flex-direction: column;
        }
      }
      .slogan {
        width: 80vw;
      }
    }
  }
  .contactIdeal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 80vw;
    margin: 6% 0;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4% 0;
      .text {
        width: 40%;
        text-align: left;
        h3 {
          @include font(medium);
        }
        p {
          @include font(smaller);
        }
      }
      .chart {
        width: 40%;
        transform: translateY(8%);
        opacity: 0;
        img {
          width: 100%;
        }
      }
    }
    @media #{$tablet} {
      .block {
        flex-direction: column;
        .text {
          width: 100%;
        }
        .chart {
          width: 100%;
          margin-top: 4%;
        }
      }
    }
  }
}
</style>
