<template>
  <div id="GoogleAds">
    <header>
      <top-title title="Google 廣告" subTitle></top-title>
    </header>
    <page-main-body
      title="靈活選用多元廣告來接觸目標客群"
      subTitle
      paragraph1="Google Ads 提供文字搜尋廣告、圖像多媒體廣告、YouTube 影片廣告和應用程式內行動廣告，讓您以不同方式接觸目標客群。"
    ></page-main-body>
    <div id="innerNav">
      <div
        class="navTopic"
        @mouseover="navHov"
        @mouseout="navHovOut"
        @click="chooseNav(1)"
        style="color:#fff;"
      >總覽</div>
      <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(2)">搜尋廣告</div>
      <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(3)">多媒體廣告</div>
      <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(4)">影片廣告</div>
      <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(5)">應用程式廣告</div>
      <div id="navUnderLine"></div>
    </div>
    <div id="G-pages">
      <goverview v-show="currentTopic == 1"/>
      <gsearch v-show="currentTopic == 2"/>
      <gmedia v-show="currentTopic == 3"/>
      <gvideo v-show="currentTopic == 4"/>
      <gapp v-show="currentTopic == 5"/>
      <div style="margin-bottom: 4%;" @click="nextTopic" v-if="currentTopic != 5">
        <span class="next"> 往下一篇
        <i class="fas fa-angle-right"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import Goverview from "@/components/Goverview.vue";
import Gsearch from "@/components/Gsearch.vue";
import Gmedia from "@/components/Gmedia.vue";
import Gvideo from "@/components/Gvideo.vue";
import Gapp from "@/components/Gapp.vue";

export default {
  name: "GoogleAds",
  components: {
    topTitle,
    pageMainBody,
    Goverview,
    Gsearch,
    Gmedia,
    Gvideo,
    Gapp
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$parent.inAdPage = true;
    this.navHovOut();
  },
  destroyed() {
    this.$parent.inAdPage = false;
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      currentTopic: 1
    };
  },
  methods: {
    navHov(e) {
      $(".navTopic").css({"color": "#707070"})
      $("#navUnderLine").width($(e.target)[0].clientWidth * 1.2 - 2);
      var left =
        $(e.target).offset().left -
        $("#innerNav").offset().left -
        ($(e.target)[0].clientWidth * (1.2 - 1)) / 2;
      $("#navUnderLine").css({ left: left - 2 + "px" });
    },
    navHovOut() {
      $("#navUnderLine").css({
        left:
          $(".navTopic")[this.currentTopic - 1].offsetLeft -
          ($(".navTopic")[this.currentTopic - 1].clientWidth * (1.2 - 1)) / 2 -
          2 +
          "px"
      });
      $("#navUnderLine").width(
        $(".navTopic")[this.currentTopic - 1].clientWidth * 1.2 - 2
      );
      $("#navUnderLine").height(
        $(".navTopic")[this.currentTopic - 1].clientHeight * 0.9
      );
      $(".navTopic")[this.currentTopic - 1].style.color = "#fff";
    },
    handleResize() {
      this.navHovOut();
    },
    chooseNav(idx, plus) {
      this.currentTopic = idx;
      this.navHovOut();
    },
    nextTopic() {
      var vm = this;
      $("html, body").animate(
        { scrollTop: $("#innerNav").offset().top - 150 },
        1200,
        "swing"
      );
      setTimeout(() => {
        vm.currentTopic == 5 ?
        vm.currentTopic = 1 :
        vm.currentTopic++;
        vm.navHovOut();
      }, 1200);
    }
  },
  watch: {
    currentTopic() {
      $(".navTopic").css({"color": "#707070"});
      $(".navTopic")[this.currentTopic - 1].style.color = "#fff";
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../../assets/image/service/mediaAds/GoogleAds/topBanner.jpg");
}

#GoogleAds {
  display: flex;
  flex-direction: column;
  align-items: center;
  #innerNav {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 4% 0;
    .navTopic {
      cursor: pointer;
      margin: 0 3%;
      transition: 0.3s;
      @include font(small);
      @media #{$phone} {
        font-size: 14px;
      }
      @media #{$slim} {
        font-size: 11px;
      }
      font-weight: 300;
      white-space: nowrap;
      &:hover {
        color: #fff !important;
        & ~ div {
          color: #707070 !important;
        }
      }
    }
    #navUnderLine {
      position: absolute;
      margin: 0;
      padding: 4px;
      height: 30px;
      bottom: -4px;
      transition: 0.3s;
      border-radius: 25px;
      background-color: $mainBlue;
      z-index: -1;
    }
  }
  #G-pages {
    width: 80vw;
    max-width: 1200px;
    position: relative;
    .next {
      cursor: pointer;
      color: #fff;
      padding: 4px 12px;
      border-radius: 25px;
      background-color: $mainBlue;
    }
  }
}
</style>
