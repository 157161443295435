<template>
  <div id="g-search">
    <div class="seen">
      <div class="text">
        <h2>讓目標客群在搜尋的關鍵時刻看見您</h2>
        <p>消費者上網搜尋您的商品和服務時，您的搜尋廣告會並列顯示在 Google 搜尋結果旁；有人點擊廣告連往您的網站或致電給您時，您才需要付費。</p>
      </div>
      <img class="example" src="../assets/image/service/mediaAds/GoogleAds/search/example.gif">
    </div>
    <h2>搜尋廣告活動</h2>
    <div class="search">
      <div class="topic">
        <div class="num">1</div>
        <h3>建議適合預算</h3>
        <p>我們將建議最適合您現階段的廣告預算，與全方位的廣告計畫。</p>
      </div>
      <div class="topic">
        <div class="num">2</div>
        <h3>關鍵字建議書</h3>
        <p>洽談好後，會給予關鍵字建議書或是您有建議的基本字詞，雙方討論分享。</p>
      </div>
      <div class="topic">
        <div class="num">3</div>
        <h3>週報告</h3>
        <p>根據每周數據變化，提供週報告向您報告，並可掌握目前的關鍵字成效。</p>
      </div>
    </div>
    <div class="contact">
      <img class="floating upper PC" src="../assets/image/service/mediaAds/GoogleAds/search/contactPC.png">
      <div class="text">
        <h2>廣告接觸目標客群的方式</h2>
        <p>無論用的是桌機、平板電腦還是智慧型手機，使用者在 Google 輸入與您所選的關鍵字相符的字詞時，您的廣告便有機會在搜尋結果上方顯示。 只有在用戶點擊廣告前往您的網站或致電您的商家後，您才需要付費。Google 每天的搜尋量高達數百萬次，只要善用廣告力量，您就能讓客戶留意到您的品牌、考慮您的產品或服務，以及採取行動。</p>
      </div>
      <div class="lowerPart">
        <img class="floating lower phone" src="../assets/image/service/mediaAds/GoogleAds/search/contactPhone.png">
        <img class="floating lower tablet" src="../assets/image/service/mediaAds/GoogleAds/search/contactTablet.png">
      </div>
    </div>
    <div class="evaluation">
      <img class="resultChart" src="../assets/image/service/mediaAds/GoogleAds/search/resultChart.png">
      <div class="text">
        <h2>評估成效、進行分析 然後做調整</h2>
        <p>廣告開始放送後,可以查看我們提供的報表,討論成效並視需要調整做法。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false
    };
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > $(".example").offset().top - hook * 0.7) {
        $(".example").addClass("fadeIn");
      }
      if (
        st > $(".search").offset().top - hook * 0.7 &&
        $(".topic").length != 0
      ) {
        $(".topic").each(function(idx) {
          setTimeout(function() {
            if($(".topic")[idx]) {
              $(".topic")[idx].classList.add("fadeIn");
            }
          }, 400 * idx);
        });
      }
      if (
        st > $(".contact").offset().top - hook &&
        st < $(".contact").offset().top + $(".contact").height()
      ) {
        var totalHeight = hook + $(".contact").height();
        var displacement =
          $(".contact").offset().top + $(".contact").height() - st;
        $(".lower").css({
          transform:
            "translateY(" + 30 * (displacement / totalHeight) + 2 + "%)"
        });
        $(".upper").css({
          transform:
            "translateY(-" +
            (30 - (30 * (displacement / totalHeight) + 2)) +
            "%)"
        });
      }
      if (st > $(".resultChart").offset().top - hook * 0.7) {
        $(".resultChart").addClass("fadeIn");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

#g-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
    align-self: flex-start;
    font-weight: 300;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .seen {
    position: relative;
    background-image: url("../assets/image/service/mediaAds/GoogleAds/search/seenBG.jpg");
    background-position: 50% 0%;
    background-size: cover;
    width: 100vw;
    height: 100vw * 0.4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    .text {
      text-align: left;
      width: 40%;
      color: #000;
    }
    .example {
      height: 100%;
      margin-top: 10%;
      transform: translateY(8%);
      opacity: 0;
    }
    @media #{$tablet} {
      flex-direction: column;
      height: auto;
      .text {
        width: 80%;
      }
      .example {
        height: auto;
        width: 40%;
      }
    }
  }
  .search {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4%;
    .topic {
      width: 28%;
      padding-right: 5%;
      transform: translateY(8%);
      opacity: 0;
      @media #{$tablet} {
        padding: 0;
      }
      @media #{$phone} {
        width: 100%;
        margin: 4% 0;
      }
      h3 {
        @include font(medium);
      }
      p {
        @include font(smaller);
      }
      .num {
        font-size: 28px;
        color: #ffffff;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        background-color: $mainRed;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .contact {
    position: relative;
    background-image: url("../assets/image/service/mediaAds/GoogleAds/search/contactBG.jpg");
    background-position: 50% 0%;
    background-size: cover;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    overflow: hidden;
    .text {
      text-align: left;
      width: 30%;
      color: #ffffff;
      margin: 8% 0;
      transform: translateX(-40%);
    }
    .floating {
      position: absolute;
      &.PC {
        width: 40%;
        top: 0;
        left: 48%;
      }
      &.phone {
        width: 14%;
        bottom: 0%;
        right: 45%;
        transform: translateY(32%);
      }
      &.tablet {
        width: 34%;
        bottom: 0%;
        right: 5%;
        transform: translateY(32%);
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      height: auto;
      .text {
        position: relative;
        width: 80%;
        transform: translate(0);
      }
      .floating {
        position: relative;
        margin: 0 2%;
        &.PC {
          width: 80%;
          top: 0;
          left: 0%;
        }
        &.phone {
          width: 20%;
          left: 0%;
        }
        &.tablet {
          width: 60%;
          left: 0%;
        }
      }
    }
  }
  .evaluation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12%;
    img {
      width: 50%;
      transform: translateY(8%);
      opacity: 0;
    }
    .text {
      text-align: left;
      padding-left: 10%;
      h2 {
        @include font(medium);
      }
      p {
        @include font(smaller);
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      img {
        width: 90%;
      }
      .text {
        padding: 0;
      }
    }
  }
}
</style>
