<template>
  <div id="g-app">
    <div class="app">
      <div class="text">
        <h2>向合適的目標對象展示您的應用程式</h2>
        <p>藉助簡單好用的應用程式廣告，向 Google 上的iOS 或 Android 使用者宣傳自家應用程式，就連廣告新手也能輕鬆辦到！</p>
      </div>
      <div class="phone">
        <img src="../assets/image/service/mediaAds/GoogleAds/app/phone.png">
      </div>
    </div>
    <h2>應用程式廣告簡介</h2>
    <div class="introduce">
      <div class="topic">
        <div class="num">1</div>
        <h3>製作與自訂廣告</h3>
        <p>首先，請直接輸入所需的廣告文字及您的所在地。我們團隊會為您設計並製作自訂應用程式廣告，您還可以在整個 Google 聯播網內放送多款不同廣告。請放心，這些設定日後都能隨時修改。</p>
      </div>
      <div class="topic">
        <div class="num">2</div>
        <h3>設定每日預算</h3>
        <p>只要定下單次安裝出價 (您願意為每次應用程式安裝所支付的金額)，我們的廣告製作和出價引擎就會完全遵照設定好的預算範圍，全力協助您推升廣告活動成效。</p>
      </div>
      <div class="topic">
        <div class="num">3</div>
        <h3>找出優質使用者</h3>
        <p>告訴我們您想要使用者在應用程式中採取的動作，我們就會鎖定有可能完成這些動作的人放送廣告。</p>
      </div>
    </div>
    <div class="benefit">
      <div class="lockon">
        <div class="description">
          <div class="title">善用每一則廣告創造更高效益</div>
          <div class="content">為了充分提高您廣告活動的獲利能力，我們團隊會測試您帳戶中的每一則廣告，並根據結果自動宣傳成效最佳的版本。此外，我們也會根據您希望找出的使用者類型進行最佳化；舉例來說，如果您的應用程式是一款多關卡遊戲，我們就會將使用者玩過的其他遊戲列入考量，並鎖定有可能晉級到至少第 5 關的玩家。</div>
        </div>
        <div class="chart">
          <img src="../assets/image/service/mediaAds/GoogleAds/app/appAd.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      tabletVideoId: "oe4AJIu74Zo",
      isPlaying: false,
      tabletPlayerVars: {
        autoplay: 0,
        rel: 0,
        playsinline: 1,
        controls: 0
      }
    };
  },
  methods: {
    play() {
      this.isPlaying
        ? this.$refs.tabletVideoYT.player.pauseVideo()
        : this.$refs.tabletVideoYT.player.playVideo();
    },
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > $(".phone").offset().top - hook * 0.7) {
        $(".phone").addClass("fadeIn");
      }
      if (
        st > $(".introduce").offset().top - hook * 0.7 &&
        $(".topic").length != 0
      ) {
        $(".topic").each(function(idx) {
          setTimeout(function() {
            if($(".topic")[idx]) {
              $(".topic")[idx].classList.add("fadeIn");
            }
          }, 400 * idx);
        });
      }
      if (st > $(".chart").offset().top - hook * 0.7) {
        $(".chart").addClass("fadeIn");
      }
    },
    handleResize() {}
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

#g-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
    align-self: flex-start;
    font-weight: 300;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .app {
    position: relative;
    background-image: url("../assets/image/service/mediaAds/GoogleAds/app/appBG.jpg");
    background-position: 50% 0%;
    background-size: cover;
    width: 100vw;
    height: 100vw * 0.4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    overflow: hidden;
    .text {
      text-align: left;
      width: 40%;
      color: #fff;
    }
    .phone {
      position: relative;
      width: 22%;
      align-self: flex-end;
      display: flex;
      align-items: flex-end;
      transform: translateY(8%);
      opacity: 0;
      img {
        width: 100%;
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      height: auto;
      .text {
        width: 80%;
      }
      .phone {
        align-self: center;
        margin-top: 4%;
        height: auto;
        width: 80%;
      }
    }
  }
  .introduce {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4%;
    .topic {
      width: 28%;
      padding-right: 5%;
      transform: translateY(8%);
      opacity: 0;
      @media #{$tablet} {
        padding: 0;
      }
      @media #{$phone} {
        width: 100%;
        margin: 4% 0;
      }
      h3 {
        @include font(medium);
      }
      p {
        @include font(smaller);
      }
      .num {
        font-size: 28px;
        color: #ffffff;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        background-color: $mainRed;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .benefit {
    width: 100%;
    margin-top: 6%;
    & > div {
      display: flex;
      margin-top: 4%;
      margin-bottom: 12%;
      justify-content: space-between;
      @media #{$phone} {
        flex-direction: column;
      }
      .description {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        width: 30%;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 16%;
        }
        .title {
          padding: 5% 0 0 0;
          @include font(small);
        }
        .content {
          padding: 10% 0;
          @include font(smaller);
        }
      }
      .chart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        transform: translateY(4%);
        opacity: 0;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
