<template>
  <div id="g-overview">
    <h3>在網路上佔有一席之地</h3>
    <div class="cards">
      <div class="card">
        <img src="../assets/image/service/mediaAds/GoogleAds/overView/promot.png">
        <div class="title">搜尋廣告</div>
        <div class="content">把握客戶搜尋有關您產品或服務的時機，在搜尋結果旁向他們顯示您的廣告，有點擊才收費讓您準確掌控預算。</div>
      </div>
      <div class="card">
        <img src=../assets/image/service/mediaAds/GoogleAds/overView/media.png>
        <div class="title">多媒體廣告</div>
        <div class="content">這類文字和橫幅廣告的放送範圍橫跨 Gmail 以及由 200 多萬個網站和應用程式組成的聯播網；無論客戶在哪，您都能鎖定他們顯示廣告。</div>
      </div>
      <div class="card">
        <img src=../assets/image/service/mediaAds/GoogleAds/overView/film.png>
        <div class="title">影片廣告</div>
        <div class="content">透過 YouTube 以活潑生動的方式宣傳商家並吸引新客戶上門，利用聲光影像的獨特魅力散播您的廣告訊息。</div>
      </div>
      <div class="card">
        <img src=../assets/image/service/mediaAds/GoogleAds/overView/app.png>
        <div class="title">應用程式廣告</div>
        <div class="content">在整個 Google 多媒體聯播網上放送廣告來宣傳您的應用程式，就算是沒有廣告製作經驗的新手也能輕鬆上手。</div>
      </div>
    </div>
    <h2>根據確切資料掌握廣告成效</h2>
    <div class="adsEffectiveness">
      <div class="knowing">
        <div class="description">
          <img src="../assets/image/service/mediaAds/GoogleAds/overView/knowing.svg">
          <div class="title">了解廣告成效</div>
          <div class="content">Google Ads 提供豐富資料，從有多少人看過廣告、造訪過網站或是撥電話給商家，全都清清楚楚，只提供特定服務/商品的專門店還可以指定特定類型的對象。</div>
        </div>
        <div class="chart chart1">
          <img src="../assets/image/service/mediaAds/GoogleAds/overView/knowing.jpg">
        </div>
      </div>
      <div class="testing">
        <div class="description">
          <img src="../assets/image/service/mediaAds/GoogleAds/overView/testing.svg">
          <div class="title">測試廣告成效</div>
          <div class="content">您可以隨時調整預算，也能隨時停止放送廣告活動；沒有合約束縛，當然也不需要支付取消費用。</div>
        </div>
        <div class="chart chart2">
          <img src="../assets/image/service/mediaAds/GoogleAds/overView/testing.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false
    };
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (
        st > $(".cards").offset().top - hook * 0.7 &&
        $(".card").length != 0
      ) {
        $(".card").each(function(idx) {
          setTimeout(function() {
            $(".card")[idx].classList.add("fadeIn");
          }, 400 * idx);
        });
      }
      if (st > $(".chart1").offset().top - hook * 0.7) {
        $(".chart1").addClass("fadeIn");
      }
      if (st > $(".chart2").offset().top - hook * 0.7) {
        $(".chart2").addClass("fadeIn");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

#g-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
    align-self: flex-start;
    font-weight: 300;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 4% 0;
    @media #{$tablet} {
      justify-content: space-evenly;
    }
    .card {
      width: 23%;
      text-align: left;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
      font-weight: 300;
      transform: translateY(4%);
      opacity: 0;
      @media #{$tablet} {
        width: 42%;
        margin: 2% 0;
      }
      @media #{$phone} {
        width: 90%;
      }
      img {
        width: 100%;
      }
      .title {
        padding: 10% 9% 0 9%;
        @include font(small);
      }
      .content {
        padding: 10% 9%;
        padding-bottom: 30%;
        @include font(smaller);
      }
    }
  }
  .adsEffectiveness {
    width: 100%;
    & > div {
      display: flex;
      margin-top: 4%;
      margin-bottom: 12%;
      justify-content: space-between;
      @media #{$phone} {
        flex-direction: column;
      }
      .description {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        width: 30%;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 16%;
        }
        .title {
          padding: 5% 0 0 0;
          @include font(small);
        }
        .content {
          padding: 10% 0;
          @include font(smaller);
        }
      }
      .chart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        transform: translateY(4%);
        opacity: 0;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
