<template>
  <div id="g-video">
    <div class="story">
      <div class="text">
        <h2>用影片宣傳您的故事</h2>
        <p>用影片宣傳您的故事只有您指定的對象才會看到您的影片廣告。有人觀看您的影片時，我們才會收取廣告費用，因此您投入的每一分資金都能帶來實際成效，為您網羅對自家業務感興趣的對象。</p>
      </div>
      <div class="tablet">
        <img src="../assets/image/service/mediaAds/GoogleAds/video/tablet.png" @click="play">
        <div class="video">
        <youtube ref="tabletVideoYT"
          :video-id="tabletVideoId" :player-vars="tabletPlayerVars" width="100%" height="100%" 
          @playing="isPlaying=true" @paused="isPlaying=false" />
        </div>
      </div>
    </div>
    <h2>影片廣告簡介</h2>
    <div class="introduce">
      <div class="topic">
        <div class="num">1</div>
        <h3>準備好影片素材</h3>
        <p>您只要開設 YouTube 帳戶並上傳您製作的影片，接下來就能透過我們團隊在 YouTube 上放送廣告活動，在相關影片片頭/旁邊或搜尋結果中顯示您的影片廣告。</p>
      </div>
      <div class="topic">
        <div class="num">2</div>
        <h3>準確的受眾</h3>
        <p>無論指定客群是運動迷、音樂愛好者或對其他任何事物感興趣的人，您都可以根據年齡層、性別、地區、興趣喜好等條件來鎖定目標對象。</p>
      </div>
      <div class="topic">
        <div class="num">3</div>
        <h3>建議適合的預算</h3>
        <p>設定您的預算，然後觀看我們提供的週報表並檢討成效，做為優化調整。</p>
      </div>
    </div>
    <div class="result">
      <div class="lockon">
        <div class="description">
          <div class="title">鎖定理想客群</div>
          <div class="content">YouTube 在全球各地擁有超過 10 億名使用者，在美國所接觸到的 18 - 49 歲人士數量更遠勝任何一家有線電視聯播網，因此能夠根據您的指定年齡層、性別、地區、興趣喜好等條件協助您廣泛接觸目標客群。此外，有人觀看您的廣告時，我們才會向您收費；要是觀眾在廣告播放滿 30 秒或播完之前略過廣告，您不需支付任何費用。</div>
        </div>
        <div class="chart chart1">
          <img src="../assets/image/service/mediaAds/GoogleAds/video/idealCustomer.png">
        </div>
      </div>
      <div class="analysis">
        <div class="description">
          <div class="title">分析成果，找出帶動成效的因素</div>
          <div class="content">您可以利用 YouTube 數據分析工具找出看過自家廣告的客群，瞭解對方與廣告互動的方式。此外，廣告每日預算由您全權掌控，金額多寡完全由您決定。</div>
        </div>
        <div class="chart chart2">
          <img src="../assets/image/service/mediaAds/GoogleAds/video/result.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      tabletVideoId: "oe4AJIu74Zo",
      isPlaying: false,
      tabletPlayerVars: {
        autoplay: 0,
        rel: 0,
        playsinline: 1,
        controls: 0
      }
    };
  },
  methods: {
    play() {
      this.isPlaying
        ? this.$refs.tabletVideoYT.player.pauseVideo()
        : this.$refs.tabletVideoYT.player.playVideo();
    },
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (
        st > $(".introduce").offset().top - hook * 0.7 &&
        $(".topic").length != 0
      ) {
        $(".topic").each(function(idx) {
          setTimeout(function() {
            if($(".topic")[idx]) {
              $(".topic")[idx].classList.add("fadeIn");
            }
          }, 400 * idx);
        });
      }
      if (st > $(".chart1").offset().top - hook * 0.7) {
        $(".chart1").addClass("fadeIn");
      }
      if (st > $(".chart2").offset().top - hook * 0.7) {
        $(".chart2").addClass("fadeIn");
      }
    },
    handleResize() {}
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

#g-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
    align-self: flex-start;
    font-weight: 300;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .story {
    position: relative;
    background-image: url("../assets/image/service/mediaAds/GoogleAds/video/storyBG.jpg");
    background-position: 50% 0%;
    background-size: cover;
    width: 100vw;
    height: 100vw * 0.4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 10%;
    .text {
      text-align: left;
      width: 40%;
      color: #fff;
    }
    .tablet {
      position: relative;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        z-index: 1;
      }
      .video {
        position: absolute;
        left: 10%;
        top: 13%;
        width: 60%;
        height: 53%;
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      height: auto;
      .text {
        width: 80%;
      }
      .tablet {
        margin: 4% 0;
        height: auto;
        width: 80%;
      }
    }
  }
  .introduce {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4%;
    .topic {
      width: 28%;
      padding-right: 5%;
      transform: translateY(8%);
      opacity: 0;
      @media #{$tablet} {
        padding: 0;
      }
      @media #{$phone} {
        width: 100%;
        margin: 4% 0;
      }
      h3 {
        @include font(medium);
      }
      p {
        @include font(smaller);
      }
      .num {
        font-size: 28px;
        color: #ffffff;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        background-color: $mainRed;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .result {
    width: 100%;
    margin-top: 6%;
    & > div {
      display: flex;
      margin-top: 4%;
      margin-bottom: 12%;
      justify-content: space-between;
      @media #{$phone} {
        flex-direction: column;
      }
      .description {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        width: 30%;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 16%;
        }
        .title {
          padding: 5% 0 0 0;
          @include font(small);
        }
        .content {
          padding: 10% 0;
          @include font(smaller);
        }
      }
      .chart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        transform: translateY(4%);
        opacity: 0;
        @media #{$phone} {
          width: 90%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
